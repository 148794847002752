.button-array {
    display: flex;
    flex-direction: row;
    position: absolute;
    align-items: center;
    justify-content: center;
    left: 27%;
    padding: 1rem;

  }
  
  .array-button {
    margin: 5px;
    padding: 10px 20px;
    background-color: rgb(254, 254, 254);
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    
  }
  
  .array-button:hover {
    background-color: #ecedef;
  }